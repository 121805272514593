<template>
  <div class="about-container">
    <img :src="imageSrc" alt="Wormble" class="title-image" @click="goToHomePage">
    
    <div v-if="hasCode">

      <h1>Live Session : {{ sessionName }}</h1>
      <button @click="removeCode" class="new-button-design">Exit Session</button>
      <div class="center-button">
      <hr style="margin: 40px 0; border-top: 1px solid #ccc; width: 80%; display: block;">
      </div>
      <div ref="tableContainerRef" class="table-container" @wheel="handleWheel">
        <table>
          <tbody>
            <tr v-for="(item, index) in sessionData" :key="index" :class="{ focused: index === focusedIndex }" @click="focusRow(index)">
              <td><span style="font-weight: bold">{{ item.word }}</span><br>
              {{ item.scramble }}</td>
              <td>
                <div class="star" :class="{ 'star-on': item.starred }" @click="toggleStar(index)">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55" width="30" height="30">
                    <path xmlns="http://www.w3.org/2000/svg" style="" d="M26.285,2.486l5.407,10.956c0.376,0.762,1.103,1.29,1.944,1.412l12.091,1.757 c2.118,0.308,2.963,2.91,1.431,4.403l-8.749,8.528c-0.608,0.593-0.886,1.448-0.742,2.285l2.065,12.042 c0.362,2.109-1.852,3.717-3.746,2.722l-10.814-5.685c-0.752-0.395-1.651-0.395-2.403,0l-10.814,5.685 c-1.894,0.996-4.108-0.613-3.746-2.722l2.065-12.042c0.144-0.837-0.134-1.692-0.742-2.285l-8.749-8.528 c-1.532-1.494-0.687-4.096,1.431-4.403l12.091-1.757c0.841-0.122,1.568-0.65,1.944-1.412l5.407-10.956 C22.602,0.567,25.338,0.567,26.285,2.486z"/>
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="center-button">
      <hr style="margin: 40px 0; border-top: 1px solid #ccc; width: 80%; display: block;">
      </div>
      <h1>Timer</h1>
      <br>
      <TimerComponent ref="timerComponent"/>
      <br>
      <div class="center-button">
        <button @click="submitTime" class="new-button-design">
          Submit Time For <b> {{ selectedWord }} </b>...
        </button>
      </div>
    </div>
    <div v-if="!hasCode">
      <input v-model="code" placeholder="Enter Session Code" />
      <button @click="saveCode">Save Code</button>
    </div>
  </div>
</template>

<script>
import axios from '../axios';
import TimerComponent from './TimerComponent.vue';
import { v4 as uuidv4 } from 'uuid';

export default {
  components: { TimerComponent },
  name: 'AboutPage',
  data() {
    return {
      imageSrc: require('@/assets/WormbleRe.png'),
      sessionData: [],
      focusedIndex: null,
      rowHeight: 30, // adjust this value to match the height of your table rows
      hasCode: false,
      code: '',
      selectedWord: '',
      sessionName: '',
    };
  },
  mounted() {
    const sessionCode = localStorage.getItem('sessionCode');
    this.getSessionName(sessionCode);

    const storedCode = localStorage.getItem('sessionCode');
    console.log('Mounted, so getting stored sessioncode', storedCode);

    if (storedCode) {
      this.hasCode = true;
    }
    this.ws = new WebSocket('wss://wormble.swiftcubing.com/ws');
    this.ws.onopen = () => {
      console.log('Websocket Connection Established');
      this.fetchSessionData();
    };
    this.ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'NewLiveData') {
        console.log('Received NewLiveData message:');
        this.fetchSessionData();
        setTimeout(() => {
      // Update focusedIndex to the last item in sessionData
          this.focusedIndex = this.sessionData.length - 1;

          // Scroll table to the bottom
          //this.$refs.tableContainerRef.scrollTop = this.$refs.tableContainerRef.scrollHeight;
          if (this.$refs.tableContainerRef) {
            this.$refs.tableContainerRef.scrollTop = this.$refs.tableContainerRef.scrollHeight;
          }
        }, 100); // adjust the delay as needed
      }

    };
    this.ws.onclose = () => {
      console.log('WebSocket connection closed');
    };

  },
  watch: {
    focusedIndex: function(newValue) {
      if (this.sessionData.length > 0 && newValue !== null && newValue >= 0 && newValue < this.sessionData.length) {
        const selectedWord = this.sessionData[newValue].word;
        this.updateSelectedWord(selectedWord);
      }
    }
  },
  methods: {
    async getSessionName(sessionCode) {
      try {
        const response = await axios.post('/get-session-name', { sessionCode });
        this.sessionName = response.data.sessionName;
      } catch (error) {
        console.error('Error fetching session name:', error);
      }
    },
    
    toggleStar(index) {
      if (this.sessionData[index].starred){
        //do nothing, the star is already toggled on. 
      }else{
        //do the toggle and submit the info to userdata
        this.sessionData[index].starred = !this.sessionData[index].starred;
        //submit user data. 
        const userCode = this.getUserCode();
        const sessionCode = localStorage.getItem('sessionCode');
        const dataType = 'favourites';
        const dataIsForWord = this.sessionData[this.focusedIndex].word;
        const extraData = '';
        axios.post('/update-user-data', {
          userCode,
          sessionCode,
          dataType,
          dataIsForWord,
          extraData,
        });

      }
    },
    updateSelectedWord(word) {
      this.selectedWord = word.substring(0, 10);
    },
    submitTime() {
      const time = this.$refs.timerComponent.getTime();
      console.log(time);
      const parts = time.split(':');
      const minutes = parseInt(parts[0]) * 60 * 1000;
      const secondsAndMilliseconds = parts[1].split('.');
      const seconds = parseInt(secondsAndMilliseconds[0]) * 1000;
      const milliseconds = parseInt(secondsAndMilliseconds[1]) * 10;
      const totalTime = minutes + seconds + milliseconds;
      console.log(totalTime);
      const userCode = this.getUserCode();
      const sessionCode = localStorage.getItem('sessionCode');
      const dataType = 'time';
      const dataIsForWord = this.sessionData[this.focusedIndex].word;
      const extraData = totalTime;

      axios.post('/update-user-data', {
        userCode,
        sessionCode,
        dataType,
        dataIsForWord,
        extraData,
      });
    },

    getUserCode() {
      //localStorage.removeItem('userCode'); // I just used this to erase existing bad userCode that was too long
      const storedUserCode = localStorage.getItem('userCode');
      if (storedUserCode) {
        return storedUserCode;
      } else {
        const newUserCode = uuidv4().substring(0, 8);
        localStorage.setItem('userCode', newUserCode);
        return newUserCode;
      }
    },
    saveCode() {
      localStorage.setItem('sessionCode', this.code);
      this.hasCode = true;
      this.fetchSessionData();
    },
    removeCode() {
      localStorage.removeItem('sessionCode');
      this.hasCode = false;
    },
    goToHomePage() {
      this.$router.push({ name: 'ConvertForm' });
    },
    /*
    async fetchSessionData() {
      try {
        //const response = await axios.post('/latest-session-data');
        const storedCode = localStorage.getItem('sessionCode');
        const response = await axios.post('/latest-session-data', { sessionCodeToGet: storedCode });
        this.sessionData = response.data;
        this.focusedIndex = this.sessionData.length - 1;

      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    },
*/
    async fetchSessionData() {
      try {
        const storedCode = localStorage.getItem('sessionCode');
        const response = await axios.post('/latest-session-data', { sessionCodeToGet: storedCode });
        this.sessionData = response.data.map(item => ({ ...item, starred: false }));
        this.focusedIndex = this.sessionData.length - 1;
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    },
    handleWheel(event) {
      event.preventDefault();
      if (event.deltaY > 0) {
        // scroll down
        if (this.focusedIndex < this.sessionData.length - 1) {
          this.focusedIndex++;
        }
      } else {
        // scroll up
        if (this.focusedIndex > 0) {
          this.focusedIndex--;
        }
      }
      this.centerSelectedRow();
    },
    focusRow(index) {
      this.focusedIndex = index;
      this.centerSelectedRow();
    },
    centerSelectedRow() {
      if (!this.$refs.tableContainerRef) return;
      const table = this.$refs.tableContainerRef.querySelector('table');
      const tbody = table.querySelector('tbody');
      const selectedRow = tbody.children[this.focusedIndex];
      if (!selectedRow) return; // Add this check
      const selectedRowTop = selectedRow.offsetTop;
      const tableContainerHeight = this.$refs.tableContainerRef.offsetHeight;
      const selectedRowHeight = selectedRow.offsetHeight;
      const targetScrollTop = selectedRowTop - (tableContainerHeight / 2) + (selectedRowHeight / 2);
      this.$refs.tableContainerRef.scrollTop = targetScrollTop;
    }
  },
};
</script>

<style scoped>
.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title-image {
  width: 80%;
  height: auto;
  max-width: 400px;
  margin-bottom: 20px;
  cursor: pointer; /* Add cursor pointer to indicate clickable image */
}

h1 {
  color: #FF5733; /* Bright orange color */
  margin-bottom: 20px;
}

p {
  color: #ffb18e; /* Lighter orange color */
  font-size: 1.1em;
  text-align: center;
  max-width: 600px;
}

.table-container {
  position: relative;
  width: 100%;
  height: 300px; /* assuming 3 rows of 50px each */
  overflow-y: scroll;
  scrollbar-width: none; /* hide scrollbar */
}

table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

td {
  border: none;
  padding: 10px;
  text-align: center;
  height: 30px; /* set row height */
}

tr.focused td {
  color: #ffb18e;
  font-size: 1.9em;
}

.star svg path {
  fill: none;
  stroke: #ffb18e;
  stroke-width: 2;
}

.star-on svg path {
  fill: #ffb18e;
}

.new-button-design {
  padding: 10px 20px;
  background-color: #ff3900;
  color: #ffffff; /* Dark grey text color */
  border: 2px solid #ff3900;
  border-radius: 5px;
  font-size: 1.0em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  margin: 0 5px; /* Add margin to separate the buttons */
}

.center-button {
  display: flex;
  justify-content: center;
}
</style>