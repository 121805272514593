<template>



  <div id="whitelist-message" v-if="!loggedIn">
    <h1> At this stage, Running a Live Session is only available to Certain Whitelisted People. If that is you, please login below</h1>
    <h1>  </h1>
    <GoogleLogin :callback="callback"/>
  </div>

  <div v-if="loggedIn">

    <div v-if="isFinished">
      <!-- Session summary content will go here -->
      <h1>Session Summary</h1>
      <!-- ... -->


      <h2> Most Favourite is ... </h2>  <h1> {{ mostFavouritedItem }}</h1>
      <br>
      <button @click="backToSession" class="sign-out-button">Back to Live Leader</button>
      <br>
      <h2> Fastest is ... </h2>  <h1> {{ fastestItem }}</h1>
      <br>
      <button @click="backToSession" class="sign-out-button">Back to Live Leader</button>


    </div>

    <div v-if="!isFinished">
      
    

      <div class="container">
      <div class="title-container">
        <img :src="imageSrc" alt="Wormble" class="title-image">
      </div>
      
      <h1 @click="toggleCurrentSession" style="cursor: pointer">
        Current Session 
        <span v-if="showCurrentSession">▼</span>
        <span v-else>▶</span>
      </h1>
      <div v-if="showCurrentSession">
        <h2> {{ currSessName }} </h2> 
        <h2> CODE: {{ currSessCode }} </h2> 
      </div>



        
        <div class="form-group">
          
          <h1 @click="toggleSessionManagement" style="cursor: pointer">
          Session Management 
          <span v-if="showSessionManagement">▼</span>
          <span v-else>▶</span>
        </h1>
        <div v-if="showSessionManagement">
          <div class="form-group">
            <form @submit.prevent="startNewSession">
              <div style="display: flex">
                <input v-model="sessionName" placeholder="Enter New Session Name" class="session-input-field" ref="sessionInputField" />
                <button class="new-session-button">New Session</button>
              </div>
            </form>
            <br>
            <button @click="signOut" class="sign-out-button">Sign Out</button>
            <br>
            <button @click="finishSession" class="sign-out-button">Finish Session</button>

          </div>
        </div>
        
        </div>

        <hr style="margin: 40px 0; border-top: 1px solid #ccc; width: 80%; display: block;">

        <form @submit.prevent="convertWord">
          <input v-model="word" placeholder="Enter a word" class="input-field" ref="inputField" />
        </form>
        <p v-if="error" class="error-text">{{ error }}</p>
        <div class="button-group">
          <button @click="convertWord" class="convert-button">Convert to 3x3 Scramble</button>
          <button 
            v-if="showCopyButton" 
            @click="copyURL" 
            class="copy-button" 
            :title="copyTooltip">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title>Copy</title>
              <desc>A solid styled icon from Orion Icon Library.</desc>
              <path data-name="layer2" d="M14 14h50v50H14z"></path>
              <path data-name="layer1" d="M50 0H0v50h10V10h40V0z"></path>
            </svg>
          </button>
        </div>
      
      <p v-if="result" class="result-text">{{ result }}</p>
      <p v-if="convertedWord" class="converted-word">({{ convertedWord }})</p>
    </div>
    <TimerComponent v-if="timerEnabled" ref="timerComponent" />


  </div>

  </div>

</template>

<script>
import axios from '../axios';
import TimerComponent from './TimerComponent.vue';
import { ref } from 'vue';
import { decodeCredential } from 'vue3-google-login';
import { onMounted } from 'vue';


export default {
  setup() {
    const currSessCode = ref(null)
    const currSessName = ref(null)
    const whitelist = ref(null)
    const token = ref(null)
    const userEmail = ref(null)
    const loggedIn = ref(false)
    const callback = (response) => {
      // This callback will be triggered when the user selects or logs in to
      // their Google account from the popup
      console.log("Handle the response", response);
      const userData = decodeCredential(response.credential)
      console.log("Handle the userData", userData)
      userEmail.value = userData.email;
      const email = userData.email;
      console.log("User's email address:", email);
      token.value = response.credential;
      loggedIn.value = true;

      axios.post('/check-liveleader', { email: email })
        .then((response) => {
          if (response.data.isWhitelisted) {
            console.log('User is on the whitelist');
            // Allow access to the page
            localStorage.setItem("token", token.value);

          } else {
            console.log('User is not on the whitelist');
            // Deny access to the page
            token.value = null;
            loggedIn.value = false;
          }
        })
        .catch((error) => {
          console.error(error);
        }); 



    }

    const signOut = () => {
      localStorage.removeItem("token");
      token.value = null;
      loggedIn.value = false;
    }

    const checkLoginStatus = () => {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        token.value = storedToken;
        loggedIn.value = true;
      }
    }

    checkLoginStatus();

    onMounted (async () => {
      console.log('Mounted lifecycle hook called');
      console.log('Checking Login Status');
      checkLoginStatus();
      const userData = decodeCredential(localStorage.getItem("token"));
      const storedEmail = userData.email
      userEmail.value = storedEmail;
      console.log("Just Tried to access email from local storage", userEmail.value);
      currSessCode.value =  localStorage.getItem("sesCode");
      currSessName.value =  localStorage.getItem("sesName"); 
    });

    return {
      callback,
      signOut,
      loggedIn,
      whitelist,
      userEmail,
      currSessCode,
      currSessName
    }
  },
  
  components: {
    TimerComponent
  },
  props: {
    timerEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      word: '',
      result: '',
      error: '', // State to handle error messages
      copyTooltip: 'Copy URL to share word',
      converted: false, // State to track if the word has been converted
      convertedWord: '', // State to store the converted word
      imageSrc: require('@/assets/WormbleRe.png'),
      showTimer: false, // Initially hide the timer
      sessionFileName: null,
      sessionLoadedFromURL: false,
      userDetails: null,
      showCurrentSession: true,
      showSessionManagement: true,
      isFinished: false,
      mostFavouritedItem: '',
      fastestItem: '',

    };
  },

  computed: {
    showCopyButton() {
      // Show the copy button only if a word has been loaded from the URL or the convert button has been clicked
      return this.converted;
    },
    /*
    isLoggedIn() {
      const gauth = this.$gAuth.getAuthInstance();
      return gauth.isSignedIn.get();
    },
*/
  },
    

    beforeMount() {
      console.log('Before mount lifecycle hook called');
    },
    created() {
      console.log('Created lifecycle hook called');
    },
    
    render(createElement) {
      window.console.log('Render function called');
      return createElement('div', 'LiveLeader component');
    },
  methods: {
    
    
    finishSession() {
      this.isFinished = true;
      //get the data 
      const sessionCode = localStorage.getItem("sesCode");
      console.log(`The Session Code is: ${sessionCode}`);

        axios.post('/get-most-favourited-item', { sessionCode })
        .then((response) => {
          //const mostFavouritedItem = response.data.mostFavouritedItem;
          //const wordTally = response.data.wordTally;
          this.mostFavouritedItem = response.data.mostFavouritedItem;

          //console.log(`Most favourited item: ${mostFavouritedItem}`);
          //console.log(`Word tally: ${JSON.stringify(wordTally)}`);
        })
        .catch((error) => {
          console.error(error);
        });

        axios.post('/get-fastest-item', { sessionCode })
        .then((response) => {
          //const mostFavouritedItem = response.data.mostFavouritedItem;
          //const wordTally = response.data.wordTally;
          this.fastestItem = response.data.fastestItem;

          //console.log(`Most favourited item: ${mostFavouritedItem}`);
          //console.log(`Word tally: ${JSON.stringify(wordTally)}`);
        })
        .catch((error) => {
          console.error(error);
        });

    },
    backToSession() {
      this.isFinished = false;
    },
    toggleCurrentSession() {
      this.showCurrentSession = !this.showCurrentSession
    },
    toggleSessionManagement() {
      this.showSessionManagement = !this.showSessionManagement
    },
    async convertWord() {
      this.$refs.inputField.blur()
      if (this.word.length > 100) {
        this.error = 'Word must be 100 characters or less.';
        this.result = '';
        this.converted = false;
        return;
      }

      try {
        this.error = ''; // Clear previous errors
        const response = await axios.post('/convert', { word: this.word, fileName: this.sessionFileName });
        if (response.data.error) {
          this.error = response.data.error;
          this.result = ''; // Clear previous result if there's an error
          this.converted = false; // Ensure the copy button is hidden
        } else {
          this.result = response.data.scramble;
          this.convertedWord = decodeURIComponent(this.word); // Store the converted word
          this.updateURL();
          this.converted = true; // Set converted to true after successful conversion
          console.log('Attempting a reculat Log into:', `session-${this.currSessCode}.json`);

          await axios.post('/log-conversion', { word: this.word, scramble: response.data.scramble, fileName: `session-${this.currSessCode}.json` });

        }
      } catch (error) {
        console.error(error);
      }
    },
    async startNewSession() {
      try {
        localStorage.removeItem("sesName");
        localStorage.removeItem("sesCode");
        console.log('Email is:', this.userEmail);
        const response = await axios.post('/start-session', { params: { createNewSession: 'true', sessionName: this.sessionName, email: this.userEmail } });
        const fileName = response.data.fileName;
        const respondedCode = response.data.sessCode;
        console.log('New session started:', response.data);
        this.sessionFileName = fileName; // Store the file name in a variable
        // Optionally reset the word and result if needed
        this.word = '';
        this.result = '';
        this.error = '';
        this.converted = false;
        this.currSessName = this.sessionName;
        localStorage.setItem("sesName", this.sessionName);
        this.currSessCode = respondedCode ;
        localStorage.setItem("sesCode", respondedCode);
        console.log('TheSessionCode is:', this.currSessCode);

        
      } catch (error) {
        console.error('Error starting latest session:', error);
      }
    },
   

    toggleTimer() {
      this.showTimer = !this.showTimer;
    },
    updateURL() {
      const newPath = `${window.location.pathname}?w=${encodeURIComponent(this.word)}&session=${`session-${this.currSessCode}.json`}`;
      window.history.pushState({ path: newPath }, '', newPath);
    },
    /**
     * Loads a word from the URL and sets the `word` and `result` data properties
     * accordingly. Also logs the conversion in the session file specified by the
     * `session` query parameter.
     */
    async loadWordFromURL() {
      const params = new URLSearchParams(window.location.search);
      const word = params.get('w');
      const session = params.get('session');

      if (session) {
        try {
          //const response = await axios.post('/start-session', { params: { session: session } });
          //const fileName = response.data.fileName;
          //if (fileName) {
            //this.sessionFileName = fileName;
            this.sessionFileName = session;
            this.sessionLoadedFromURL = true;
            console.log('This is the Session gathered from the URL:', `session-${this.currSessCode}.json`); // Add this line

          //}
        } catch (error) {
          //console.error('Error getting latest session:', error);
        }
      }
      
      if (word) {
        if (word.length > 100) {
          this.error = 'Word must be 100 characters or less.';
          this.result = '';
          this.converted = false;
          return;
        }

        this.word = word;
        try {
          const response = await axios.get(`/${encodeURIComponent(word)}`);
          if (response.data.error) {
            this.error = response.data.error;
            this.result = ''; // Clear previous result if there's an error
            this.converted = false; // Ensure the copy button is hidden
          } else {
            this.result = response.data.scramble;
            this.convertedWord = this.word; // Store the converted word
            this.converted = true; // Set converted to true if the word is loaded from URL
            console.log('Attempting a URL Log into:', `session-${this.currSessCode}.json`);
            await axios.post('/log-conversion', { word: this.word, scramble: response.data.scramble, fileName: `session-${this.currSessCode}.json` });
            
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    copyURL() {
    const urlToCopy = window.location.href;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(urlToCopy).then(() => {
        this.copyTooltip = 'Copied!';
        setTimeout(() => {
          this.copyTooltip = 'Copy URL to share word';
        }, 2000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      // Fallback for older browsers or insecure connections
      const textArea = document.createElement('textarea');
      textArea.value = urlToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        this.copyTooltip = 'Copied!';
        setTimeout(() => {
          this.copyTooltip = 'Copy URL to share word';
        }, 2000);
      } catch (err) {
        console.error('Failed to copy using fallback: ', err);
      }
      document.body.removeChild(textArea);
    }
  }
  },
  //mounted() {
    //this.loadWordFromURL();
  //},
  
  async mounted() {
    //nothing left in mounted.
    
    this.loadWordFromURL();

    if (!this.sessionLoadedFromURL) {
      try {
        const response = await axios.post('/start-session', { params: { createNewSession: 'false' } });
        const fileName = response.data.fileName;
        if (fileName) {
          this.sessionFileName = fileName;
        }
      } catch (error) {
        console.error('Error getting latest session:', error);
      }
    }
  },



};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.container {
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent; /* Make the background transparent */
  padding: 10px;
  overflow-y: auto;

}

h1 {
  color: #FF5733; /* Bright orange color */
  margin-bottom: 10px;
}

h2 {
  color: #FFFFFF; /* Bright orange color */
  margin-bottom: 5px;
}

.title-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
    overflow-y: auto;

}

.title-image {
  width: 100%; /* Default width for smaller screens */
  height: auto;
  max-width: 400px; /* Maximum width for larger screens */
}

@media (min-width: 768px) {
  .title-image {
    width: 60%; /* Adjust width for larger screens */
    max-width: 800px; /* Ensure it doesn't exceed 400px */
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  position: relative;
}

.input-field {
  width: calc(100% - 10px); /* Adjust width to fill the container with some padding */
  max-width: 500px; /* Maximum width for larger screens */
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 1.0em;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  position: relative;
}

@media (min-width: 768px) {
  .input-field {
    width: 150%; /* Increase width for larger screens */
    left: 50%;
    transform: translateX(-50%);
  }
}

.error-text {
  color: #ffb18e;
  font-size: 0.9em;
  margin-top: -10px;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  justify-content: center; /* Center the buttons horizontally */
  margin-top: 10px; /* Add some space between the input and buttons */
  width: 100%; /* Make the button group take the full width of its container */
}

.convert-button {
  padding: 10px 20px;
  background-color: #ff3900;
  color: #202020; /* Dark grey text color */
  border: 2px solid #ff3900;
  border-radius: 5px;
  font-size: 1.0em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  margin: 0 5px; /* Add margin to separate the buttons */
}

.new-session-button {
  padding: 10px 20px;
  background-color: #ff3900;
  color: #202020; /* Dark grey text color */
  border: 2px solid #ff3900;
  border-radius: 5px;
  font-size: 1.0em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  margin: 0 5px; /* Add margin to separate the buttons */
}
.sign-out-button {
  padding: 10px 20px;
  background-color: #ff3900;
  color: #202020; /* Dark grey text color */
  border: 2px solid #ff3900;
  border-radius: 5px;
  font-size: 1.0em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Poppins', sans-serif;
  margin: 0 5px; /* Add margin to separate the buttons */
}

.convert-button:hover {
  background-color: #d52c00;
}

.copy-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  padding: 5px;
  transition: transform 0.3s ease;
}

.copy-button:hover {
  transform: scale(1.1);
}

.copy-button svg {
  width: 20px;
  height: 20px;
}

.copy-button svg path {
  fill: #ff3900; /* Set the fill color to match the convert button */
}

.result-text {
  font-size: 1.5em;
  margin-top: 20px;
  color: #ffb18e;
}

.converted-word {
  font-size: 0.9em;
  color: #ffb18e;
  margin-top: 10px;
}

#whitelist-message {
  text-align: center;
  padding: 20px;
}

#whitelist-message h1 {
  margin-bottom: 20px;
}

#whitelist-message .GoogleLogin {
  margin: 0 auto;
}

</style>
