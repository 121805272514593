<template>
  <div class="timer">
    <div class="display">
      <span v-if="showMinutes">{{ displayMinutes }}:</span>{{ displaySeconds }}.{{ displayHundredths }}
      <div :class="['status-circle', circleState]"></div>
    </div>
    <a href="#" class="reset-link" @click="resetTimer">Reset</a>
  </div>
</template>

<script>
export default {
  name: 'TimerComponent',
  data() {
    return {
      startTime: null,
      elapsedTime: 0,
      timerInterval: null,
      running: false,
      awaitingStart: true,
      expectingStop: false,
      holdTimeout: null,
      holdStart: false,
      resetPending: false,
      keyPressed: false,
    };
  },
  computed: {
    displayMinutes() {
      return this.pad(Math.floor(this.elapsedTime / 60000));
    },
    displaySeconds() {
      const seconds = Math.floor((this.elapsedTime % 60000) / 1000);
      return seconds >= 10 || this.showMinutes ? this.pad(seconds) : seconds.toString();
    },
    displayHundredths() {
      return this.pad(Math.floor((this.elapsedTime % 1000) / 10), 2);
    },
    showMinutes() {
      return Math.floor(this.elapsedTime / 60000) > 0;
    },
    circleState() {
      if (this.running) {
        return 'flashing-orange';
      } else if (this.holdStart) {
        return this.resetPending ? 'solid-green' : 'solid-white';
      } else {
        return 'solid-orange';
      }
    }
  },
  methods: {
    getTime() {
      // Return the current time on the timer
      return this.displayMinutes + ':' + this.displaySeconds + '.' + this.displayHundredths;
    },
    startTimer() {
      if (!this.running) {
        // console.log("Starting timer...");
        this.running = true;
        this.startTime = performance.now() - this.elapsedTime;
        this.timerInterval = setInterval(this.tick, 10);
        this.awaitingStart = false;
      }
    },
    stopTimer() {
      if (this.running) {
        // console.log("Stopping timer...");
        this.running = false;
        clearInterval(this.timerInterval);
        this.expectingStop = false;
      }
    },
    tick() {
      this.elapsedTime = performance.now() - this.startTime;
    },
    resetTimer() {
      // console.log("Resetting timer...");
      this.elapsedTime = 0;
      this.startTime = null;
      this.running = false;
      clearInterval(this.timerInterval);
    },
    pad(number, length = 2) {
      return number.toString().padStart(length, '0');
    },
    handleKeydown(event) {
      if (event.code === 'Space' && !this.keyPressed) {
        // console.log("Key down: Space");
        this.keyPressed = true;
        if (this.running) {
          this.stopTimer();
          this.expectingStop = true;
        } else if (!this.running && !this.holdStart) {
          this.holdStart = true;
          this.resetPending = false;
          this.holdTimeout = setTimeout(() => {
            this.resetTimer();
            this.resetPending = true;
          }, 500); // Change hold down requirement to 0.5 seconds
        }
      }
    },
    handleKeyup(event) {
      if (event.code === 'Space') {
        // console.log("Key up: Space");
        this.keyPressed = false;
        if (this.holdStart && !this.resetPending) {
          clearTimeout(this.holdTimeout);
          this.holdStart = false;
          return; // Do nothing if the hold threshold was not met
        }
        if (!this.running && this.awaitingStart && !this.expectingStop && this.resetPending) {
          this.startTimer();
        }
        this.awaitingStart = !this.running;
        this.expectingStop = false;
        this.holdStart = false;
      }
    },
    handleTouchstart() {
      // console.log("Touch start");
      if (this.running) {
        this.stopTimer();
        this.expectingStop = true;
      } else if (!this.running && !this.holdStart) {
        this.holdStart = true;
        this.resetPending = false;
        this.holdTimeout = setTimeout(() => {
          this.resetTimer();
          this.resetPending = true;
        }, 500); // Change hold down requirement to 0.5 seconds
      }
    },
    handleTouchend() {
      // console.log("Touch end");
      if (this.holdStart && !this.resetPending) {
        clearTimeout(this.holdTimeout);
        this.holdStart = false;
        return; // Do nothing if the hold threshold was not met
      }
      if (!this.running && this.awaitingStart && !this.expectingStop && this.resetPending) {
        this.startTimer();
      }
      this.awaitingStart = !this.running;
      this.expectingStop = false;
      this.holdStart = false;
    },
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);
    window.addEventListener('touchstart', this.handleTouchstart);
    window.addEventListener('touchend', this.handleTouchend);
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
    window.removeEventListener('touchstart', this.handleTouchstart);
    window.removeEventListener('touchend', this.handleTouchend);
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Sometype+Mono:wght@500&display=swap');

.timer {
  font-size: 2em;
  font-family: 'Sometype Mono', monospace;
  color: #ff3900;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.display {
  background-color: transparent;
  color: #ff3900;
  padding: 10px;
  border-radius: 10px;
  border: 5px solid #ff3900;
  width: 8.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Add this to position the circle inside the display */
  user-select: none;
}

.status-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

.solid-orange {
  background-color: #ffb18e;
}

.solid-white {
  background-color: white;
}

.solid-green {
  background-color: green;
}

.flashing-orange {
  background-color: #ffb18e;
  animation: flash 1s infinite;
}

@keyframes flash {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.reset-link {
  margin-top: 10px;
  color: #ff3900;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.4em;
  user-select: none;
}

.reset-link:hover {
  text-decoration: underline;
}
</style>
